export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [11,[2]],
		"/(app)/admin": [12,[2,3]],
		"/(app)/admin/settings": [13,[2,3]],
		"/(app)/ai": [14,[2,4]],
		"/(app)/ai/c/[id]": [15,[2,4]],
		"/(app)/ai/playground": [16,[2,4,5]],
		"/(app)/ai/workspace": [17,[2,4,6]],
		"/(app)/ai/workspace/documents": [18,[2,4,6]],
		"/(app)/ai/workspace/functions": [19,[2,4,6]],
		"/(app)/ai/workspace/functions/create": [20,[2,4,6]],
		"/(app)/ai/workspace/functions/edit": [21,[2,4,6]],
		"/(app)/ai/workspace/models": [22,[2,4,6]],
		"/(app)/ai/workspace/models/create": [23,[2,4,6]],
		"/(app)/ai/workspace/models/edit": [24,[2,4,6]],
		"/(app)/ai/workspace/prompts": [25,[2,4,6]],
		"/(app)/ai/workspace/prompts/create": [26,[2,4,6]],
		"/(app)/ai/workspace/prompts/edit": [27,[2,4,6]],
		"/(app)/ai/workspace/tools": [28,[2,4,6]],
		"/(app)/ai/workspace/tools/create": [29,[2,4,6]],
		"/(app)/ai/workspace/tools/edit": [30,[2,4,6]],
		"/auth": [34],
		"/error": [35],
		"/(app)/lockers": [31,[2,7]],
		"/(app)/lockers/dashboard": [32,[2,7]],
		"/(app)/lockers/requests": [33,[2,7]],
		"/public": [36,[8]],
		"/public/findmylocker": [37,[8,9]],
		"/public/forms/lockerim": [38,[8,10]],
		"/s/[id]": [39]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';